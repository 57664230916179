import styled from "styled-components";
import { Swiper } from "swiper/react";

import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT, fontWeights, colors } from "@util/constants";
import { Container } from "@util/standard";

// HOME HERO

export const HeroWrapper = styled(Container)`
  width: 96%;
  height: 83vh;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 100vh;
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
`;

export const StyledSwiper = styled(Swiper)`
  .swiper-pagination {
    text-align: left;
    margin: 0 0 20px 30px;
  }

  .swiper-pagination-bullet {
    background-color: transparent;
    border: 1px solid white;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: white;
  }

  width: 100%;
  height: 100%;
  margin: auto;
`;
export const ImageWrapper = styled(Container)`
  width: 100%;
  height: 40vh;
  margin: auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 40vh;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 25vh;
  }
`;

export const SlideTextWrapper = styled(Container)`
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  h1,
  h2,
  h3,
  h4,
  p {
    width: 100%;
    margin-top: 0px;
  }

  a {
    width: max-content;
    background-color: ${colors.navy};
    margin: auto;
    padding: 16px 25px;
    color: ${colors.white};
    text-decoration: none;
    border-radius: 4px;
    border: 1px solid transparent;
    &:hover {
      filter: none;
      background-color: transparent;
      color: ${colors.white};
      border: 1px solid ${colors.white};
    }
    transition: all 0.2s;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 85%;
    margin: auto;
  }
`;

export const DateContainer = styled(Container)`
  width: 75%;
  max-width: 1000px;
  min-width: 884px;
  margin: auto;
  z-index: 2;
  justify-content: space-between;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 92%;
    min-width: 0px;
    justify-content: initial;
  }
`;

export const DateBlock = styled.p<{ marginOn?: boolean }>`
  font-size: 20px;
  font-weight: ${fontWeights.medium};
  color: ${colors.white};
  border: 2px solid ${colors.white};
  padding: 20px 30px;
  border-radius: 10px;
  ${({ marginOn }) => marginOn && `margin-right: 10px;`};
  text-align: center;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin-right: 10px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 14px;
    padding: 15px;
  }
`;

// END OF HOME HERO
